import { navigate } from 'gatsby';
import { SubmissionError } from 'redux-form';

import {
  getJwtProperties,
  getJwtToken,
  hasActiveToken,
  registerJwtToken,
  unRegisterJwtToken,
} from '../helpers/jwtHelpers';
import sessionStorageHelper from '../helpers/sessionStorageHelper';
import { get, post } from '../services/api';
import createAction from '../services/createAction';
import { fetchCustomersSimple } from './customers';
import {
  flashMessageAdd,
  flashMessageRemoveAllButId,
  flashMessageRemoveAllViewCountButId,
  flashMessageRemoveById,
} from './flashMessages';
import { fetchOrganizationsSimple } from './organizations';

const FLASHMESSAGE_ID = 'flashmessage-logout';

const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST';
const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';
const USER_LOGOUT = 'USER_LOGOUT';

const initialState = {
  isLoggedIn: hasActiveToken(),
  isLoggingIn: false,
  isInvalid: false,
  isTimedOut: false,
  loginTime: (hasActiveToken()) ? sessionStorageHelper('get', 'loginTime') : null,
  user: {
    ...getJwtProperties(),
  },
};

// eslint-disable-next-line default-param-last
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case USER_LOGIN_REQUEST:
      return {
        ...state,
        isLoggingIn: true,
      };
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        isLoggingIn: false,
        loginTime: payload.loginTime,
        user: {
          username: payload.user.username,
          roles: payload.user.roles,
        },
      };
    case USER_LOGIN_ERROR:
      return {
        ...state,
        isLoggedIn: false,
        isLoggingIn: false,
        isInvalid: (payload.status === 401),
      };
    case USER_LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        loginTime: null,
        user: {},
      };
    default:
      return state;
  }
};

export const flashMessageRemoveAllButLogin = () => (dispatch) => {
  dispatch(flashMessageRemoveAllViewCountButId(FLASHMESSAGE_ID));
};

export const loginUser = (token) => (dispatch) => {
  registerJwtToken(token);

  const user = getJwtProperties();
  const loginTime = Date.now();

  sessionStorageHelper('set', 'loginTime', loginTime);

  const payload = {
    user,
    loginTime,
  };

  // eslint-disable-next-line
  dispatch(userLoginSuccess(payload));
  dispatch(fetchCustomersSimple());
  dispatch(fetchOrganizationsSimple());

  if (typeof window !== 'undefined') {
    navigate('/dashboard');
  }
};

export const refreshUser = () => (dispatch) => {
  const token = getJwtToken();
  const currentLoginTime = sessionStorageHelper('get', 'loginTime');
  const now = Date.now();
  const timeDiff = Math.abs(now - currentLoginTime);

  // early return when the difference is smaller than 30minutes
  if (timeDiff < 30 * 60 * 1000) {
    return null;
  }

  // Logout the user when difference is higher than an hour
  if (timeDiff > 60 * 60 * 1000) {
    dispatch(flashMessageAdd({
      id: FLASHMESSAGE_ID,
      title: 'Je bent uitgelogd',
      message: 'Omdat je te lang inactief bent geweest hebben we jou preventief uitgelogd, wel zo veilig.',
      type: 'warning',
    }));
    return dispatch(logoutUser()); // eslint-disable-line
  }

  // do the request
  return get({
    path: '/user/refresh_token',
    body: {
      token,
    },
  })
    .then((response) => {
      if (response.token) {
        registerJwtToken(token);

        const user = getJwtProperties();
        const loginTime = Date.now();

        sessionStorageHelper('set', 'loginTime', loginTime);

        const payload = {
          user,
          loginTime,
        };

        // eslint-disable-next-line
        dispatch(userLoginSuccess(payload));
        dispatch(fetchCustomersSimple());
        dispatch(fetchOrganizationsSimple());
      }
    });
};

export const userLoginRequest = createAction(USER_LOGIN_REQUEST);
export const userLoginSuccess = createAction(USER_LOGIN_SUCCESS);
export const userLoginError = createAction(USER_LOGIN_ERROR);

export const fetchLoginCheck = () => (dispatch, getState) => {
  // Get the values from the state
  const data = getState().form.loginForm.values;

  // notify the store that we are logging in
  dispatch(userLoginRequest());

  // do the request
  return post({
    path: '/login_check',
    body: data,
  })
    .then((response) => {
      if (response.status >= 400) {
        throw new Error(response.message);
      }

      if (response.token) {
        registerJwtToken(response.token);
        const user = getJwtProperties();

        const loginTime = Date.now();
        sessionStorageHelper('set', 'loginTime', loginTime);
        const payload = {
          user,
          loginTime,
        };
        dispatch(userLoginSuccess(payload));

        dispatch(flashMessageRemoveById(FLASHMESSAGE_ID));

        dispatch(fetchCustomersSimple());
        dispatch(fetchOrganizationsSimple());

        if (typeof window !== 'undefined') {
          navigate('/dashboard');
        }
      }
    })
    .catch((err) => {
      dispatch(userLoginError(err));
      throw new SubmissionError({
        username: ' ',
        password: ' ',
        _error: 'Gebruikersnaam en/of wachtwoord is niet correct ingevuld.',
      });
    });
};

export const userLogout = createAction(USER_LOGOUT);

export const logoutUser = (event) => (dispatch) => {
  if (event) {
    event.preventDefault();
  }
  unRegisterJwtToken();
  sessionStorageHelper('remove', 'customersSimple');
  sessionStorageHelper('remove', 'organizationsSimple');
  sessionStorageHelper('remove', 'loginTime');

  dispatch(flashMessageRemoveAllButId(FLASHMESSAGE_ID));
  // notify the store that we are logging out
  dispatch(userLogout());
  if (typeof window !== 'undefined') {
    navigate('/');
  }
};
